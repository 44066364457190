import React from "react";
import BulletPointsOrGrid from "./bulletPointsOrGrid";
import ReactPlayer from "react-player";
import {
  GetHrefTarget,
  Dictionary,
  IconList,
  CapitalizeFirstLetters,
  TextColorFromBackgroundColor,
} from "./common.js";
import GoogleMapReact from "google-map-react";
import { Element } from "react-scroll";
import Mailchimp from "react-mailchimp-form";

export default class ContentBlock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      copyOrConnectPopUpOpacity: 1,
    };
    this.backgroundColor = this.setBlockBackgroundColor();
  }

  setBlockBackgroundColor = () => {
    const { block, isPhone } = this.props;
    if (!block) return "white";
    if (isPhone && block.backgroundColorPhone)
      return block.backgroundColorPhone;
    if (block.backgroundColor) return block.backgroundColor;
    return "white";
  };

  getItemStyle = (item, i) => {
    const { contentArray } = this.props.block;
    var paddingBottom = 0;
    if (
      (contentArray && i === contentArray.length - 1) ||
      (contentArray[i + 1] && contentArray[i + 1].type === "spacer")
    )
      paddingBottom = 0;
    return {
      // flex: item.string ? Math.ceil( item.string.length/175 ) : 1,
      display: "flex",
      justifyContent: "center",
      // width: "100%",
      cursor: item.link || item.copyOrConnect ? "pointer" : null,
      textAlign: this.props.textAlign || "left",
      zIndex: 1,
      paddingBottom,
      backgroundColor: item.backgroundColor,
    };
  };

  getDisplayItem = ({ item, i, itemCount }) => {
    const { isPhone, computedHeight, isIOS, isAndroid, windowWidth } =
      this.props;
    const mediaStyle = {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 0,
      height: computedHeight,
      width: this.getBlockWidth(),
      objectFit: item.objectFit || "cover",
      objectPosition: item.objectPosition,
      opacity: item.opacity || 1,
    };

    if (item.type === "embed") return item.embed;

    if (item.type === "spacer")
      return (
        <div
          style={{
            width: item.width || "100%",
            height: item.height || "100%",
            ...item.customStyles,
          }}
          key={i}
        />
      );

    if (
      item.type === "videoBackground" &&
      (!isPhone || (isPhone && !item.hideOnPhone))
    )
      return (
        <video
          muted={true}
          autoPlay={true}
          loop={true}
          playsInline={true}
          style={mediaStyle}
          key={i}
        >
          <source src={item.uri} type="video/mp4" />
        </video>
      );

    if (
      item.type === "imageBackground" &&
      (!isPhone || (isPhone && !item.hideOnPhone))
    ) {
      this.backgroundColor = "black";
      return (
        <img
          src={item.uri}
          style={{ ...mediaStyle, height: "100%", ...item.customStyles }}
          alt={item.alt}
          key={i}
        />
      );
    }

    if (item.type === "image" && (!isPhone || (isPhone && !item.hideOnPhone))) {
      var imageHeight =
        isPhone && item.heightPhone ? item.heightPhone : item.height;
      var imageWidth =
        isPhone && item.widthPhone ? item.widthPhone : item.width;

      const imageMarkup = (
        <img
          src={item.uri}
          className={item.dropShadow ? "dropShadow" : ""}
          style={{
            flex: imageHeight || imageWidth ? null : 1,
            objectFit: "contain",
            height: imageHeight,
            imageWidth,
            maxWidth: item.maxWidth,
            ...item.customStyles,
          }}
          alt={item.alt}
        />
      );

      if (!item.link)
        return (
          <div
            className="flexCenter"
            style={this.getItemStyle(item, i)}
            key={i}
          >
            {imageMarkup}
          </div>
        );
      return (
        <a
          href={item.link}
          target={GetHrefTarget(item, isIOS, isAndroid)}
          className="flexCenter"
          style={this.getItemStyle(item, i)}
          key={i}
        >
          <img
            src={item.uri}
            className={item.dropShadow ? "dropShadow" : ""}
            style={{
              flex: imageHeight || imageWidth ? null : 1,
              objectFit: "contain",
              height: imageHeight,
              imageWidth,
              ...item.customStyles,
            }}
            alt={item.alt}
          />
        </a>
      );
    }

    if (
      item.type === "fixedImage" &&
      (!isPhone || (isPhone && !item.hideOnPhone))
    ) {
      const { top, left, right, bottom } = item;
      var fixedImageHeight =
        isPhone && item.heightPhone ? item.heightPhone : item.height;
      var fixedImageWidth =
        isPhone && item.widthPhone ? item.widthPhone : item.width;

      return (
        <img
          src={item.uri}
          className={item.dropShadow ? "dropShadow" : ""}
          style={{
            position: "absolute",
            top,
            left,
            right,
            bottom,
            height: fixedImageHeight,
            width: fixedImageWidth,
            zIndex: 1,
            ...item.customStyles,
          }}
          alt={item.alt}
          key={i}
        />
      );
    }

    if (
      item.type === "imageWithOverlay" &&
      (!isPhone || (isPhone && !item.hideOnPhone))
    ) {
      var overlayImageHeight = item.height;
      if (isPhone && item.heightPhone) overlayImageHeight = item.heightPhone;

      var overlayDisplayArray = [];
      for (var j = 0; j < item.overlayArray.length; j++) {
        overlayDisplayArray.push(
          this.getDisplayItem({ item: item.overlayArray[j] })
        );
      }

      return (
        <div
          className="flexCenter flexColumn relative"
          style={{
            ...this.getItemStyle(item, i),
            flex: "0 0 " + overlayImageHeight + "px",
            ...item.customStyles,
          }}
          key={i}
        >
          <div className="fillParent flexCenter">
            <img
              src={item.uri}
              style={{
                height: overlayImageHeight,
                width: overlayImageHeight,
                ...item.customStyles,
              }}
              alt={item.alt}
              key={i}
            />
          </div>
          {overlayDisplayArray}
        </div>
      );
    }

    if (item.type === "bullets") {
      return (
        <BulletPointsOrGrid
          array={item.array}
          columns={item.columns}
          columnsPhone={item.columnsPhone}
          bulletTextStyle={{
            fontSize: this.getFontSize(item.fontSize),
            ...item.customStyles,
          }}
          backgroundColor={this.backgroundColor}
          windowWidth={windowWidth}
          isPhone={isPhone}
          dataObject={this.props.dataObject}
          key={i}
        />
      );
    }

    if (item.type === "grid") {
      return (
        <BulletPointsOrGrid
          array={item.array}
          columns={item.columns}
          columnsPhone={item.columnsPhone}
          backgroundColor={this.backgroundColor}
          windowWidth={windowWidth}
          isPhone={isPhone}
          dataObject={this.props.dataObject}
          key={i}
        />
      );
    }

    if (item.type === "googleMap") {
      const LocationPin = ({ text }) => (
        <div style={{ position: "relative" }}>
          <img
            alt=""
            style={{ position: "absolute", top: -30, width: 30, height: 30 }}
            src="https://willow-web-assets.s3.ca-central-1.amazonaws.com/icons/map_marker.png"
          />
          <div style={{ position: "absolute", left: -30, width: 100 }}>
            {text}
          </div>
        </div>
      );

      return (
        <div style={{ maxWidth: "100%", ...item.customStyles }} key={i}>
          <GoogleMapReact
            bootstrapURLKeys={{
              key: "AIzaSyBT_1W4IaUlHvs8hopUQquPbPZT0iSpnzI",
            }}
            defaultCenter={{ lat: item.latitude, lng: item.longitude }}
            defaultZoom={item.zoomLevel || 10}
            yesIWantToUseGoogleMapApiInternals
            style={{ flex: 1 }}
          >
            <LocationPin
              lat={item.latitude}
              lng={item.longitude}
              text={item.markerString || "Marker"}
            />
          </GoogleMapReact>
        </div>
      );
    }

    if (item.type === "mailchimpForm")
      return (
        <div style={{ zIndex: 1, ...item.customStyles }} key={i}>
          <Mailchimp
            action={item.action}
            fields={[
              {
                name: "EMAIL",
                placeholder: "enter email address",
                type: "email",
                required: true,
              },
            ]}
            messages={{
              sending: "Sending...",
              success: "Thank you for subscribing!",
              error: "An unexpected internal error has occurred.",
              empty: "You must write an e-mail.",
              duplicate: "Too many subscribe attempts for this email address",
              button: "Subscribe",
            }}
            className="mailchimpStyle"
          />
        </div>
      );

    if (item.type === "mp3")
      return (
        <div
          className="flexCenter"
          style={{ ...this.getItemStyle(item, i), ...item.customStyles }}
          key={i}
        >
          <ReactPlayer
            url={item.uri}
            controls={true}
            height={60}
            width={this.getBlockWidth()}
          />
        </div>
      );

    if (item.type === "buttons") {
      var margin = isPhone ? "20px 60px" : "30px 90px";
      if (item.isRow) margin = "0 40px";
      return (
        <div
          id="button section"
          className={
            "flexWrap " +
            (item.isRow && (!item.isColumnOnPhone || !isPhone)
              ? "flexRow flexCenter"
              : "flexColumn flexSpaceEvenly")
          }
          style={{ zIndex: 1, margin, width: item.isRow ? "100%" : null }}
          key={i}
        >
          {item.array && item.array.map((e) => this.getButton(item, e))}
          {item.isScrollToArray && this.getScrollToLinks(item)}
        </div>
      );
    }

    if (item.type === "inlineTitle")
      return (
        <div
          className="flexColumn flexStart"
          style={{
            ...this.getItemStyle(item),
            alignItems: "flex-start",
            flex: Math.ceil(item.string.length / 100) + 1,
            width: "100%",
          }}
          key={i}
        >
          <div
            style={{
              textAlign: "left",
              fontWeight: "bold",
              marginBottom: 5,
              fontSize: this.getFontSize(item.titleFontSize),
            }}
          >
            {item.title}
          </div>
          {this.getTextItem({ item, i, itemCount })}
          {item.endTitle && (
            <div
              style={{
                textAlign: "right",
                width: "100%",
                marginBottom: 5,
                fontSize: this.getFontSize(item.endTitleFontSize),
              }}
            >
              {item.endTitle}
            </div>
          )}
        </div>
      );

    if (item.type === "scrollToBar") {
      var scrollToArray = [];
      for (var k = 0; k < item.linksArray.length; k++) {
        const linkItem = item.linksArray[k];
        scrollToArray.push(
          <a
            href={"#" + linkItem.id.replaceAll(" ", "_")}
            style={{
              cursor: "pointer",
              textAlign: "center",
              margin: "0 20px 30px 20px",
              maxHeight: item.maxDimensions,
              maxWidth: item.maxDimensions,
              fontSize: this.getFontSize(item.fontSize),
            }}
          >
            {linkItem.label}
          </a>
        );
      }
      return (
        <div
          className="flexRow flexSpaceEvenly flexWrap"
          style={{ width: "100%" }}
          key={i}
        >
          {scrollToArray}
        </div>
      );
    }

    if (item.type === "linkBar") {
      var array = [];
      for (var m = 0; m < item.linksArray.length; m++) {
        const linkItem = item.linksArray[m];
        var singleLinkArray = [];
        if (linkItem.imageUrl)
          singleLinkArray.push(
            <img
              src={linkItem.imageUrl}
              style={{
                objectFit: "contain",
                maxHeight: "100%",
                maxWidth: "100%",
              }}
              alt={linkItem.imageAlt || ""}
              key={m + "image"}
            />
          );
        if (linkItem.label)
          singleLinkArray.push(
            <div
              style={{ fontSize: linkItem.labelFontSize || 12 }}
              key={m + "label"}
            >
              {linkItem.label}
            </div>
          );
        array.push(
          <a
            href={linkItem.linkUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="flexCenter flexColumn"
            style={{
              cursor: "pointer",
              marginBottom: 30,
              maxWidth: item.maxDimensions,
              maxHeight: item.maxDimensions,
              color: "grey",
              fontSize: "12px",
            }}
            key={m + "href"}
          >
            {singleLinkArray}
          </a>
        );
      }
      return (
        <div
          className="flexRow flexSpaceEvenly flexWrap"
          style={{ width: "100%" }}
          key={i}
        >
          {array}
        </div>
      );
    }

    if (item.string) return this.getTextItem({ item, i, itemCount });
  };

  getTextItem = ({ item, i, itemCount }) => {
    const isTitle = ["title", "subTitle"].includes(item.type);
    var textAlign =
      item.textAlign || isTitle || item.centerText ? "center" : "left";
    var marginBottom = i < itemCount && !item.noMarginBottom ? 30 : 0;
    if (this.props.isFooter) marginBottom = 5;
    const style = {
      ...this.getItemStyle(item, i),
      fontSize: this.getFontSize(item.fontSize),
      fontWeight:
        [item.type, item.fontWeight].includes("bold") ||
        item.isBold ||
        ["giant", "xl"].includes(item.fontSize)
          ? "bold"
          : null,
      textAlign,
      color:
        item.color ||
        TextColorFromBackgroundColor(
          item.backgroundColor || this.backgroundColor
        ),
      WebkitTextStrokeWidth: item.textOutline ? 1 : null,
      WebkitTextStrokeColor: item.textOutline ? "black" : null,
      width: isTitle ? null : "100%",
      padding: isTitle ? "30%" : null,
      marginBottom,
    };

    if (item.backgroundColor) {
      style.backgroundColor = item.backgroundColor;
      style.padding = 10;
    }

    if (textAlign === "center") {
      delete style.width;
      style.padding = "10px 30px 10px 30px";
    }

    if (textAlign === "left") style.justifyContent = "start";

    if (item.link)
      return (
        <a
          href={item.link}
          target={GetHrefTarget(item, this.props.isIOS, this.props.isAndroid)}
          className="flexCenter"
          style={style}
          key={i}
        >
          {item.string || item}
        </a>
      );

    if (item.copyOrConnect)
      return (
        <div
          style={style}
          key={i}
          onClick={() => this.copyOrConnectPopUp(item.string, style)}
        >
          {item.string || item}
        </div>
      );

    if (item.subString) {
      return (
        <div className="flexColumn" style={style} key={i}>
          <div>{item.string || item}</div>
          <div style={{ fontSize: this.getFontSize(item.subStringFontSize) }}>
            {item.subString}
          </div>
        </div>
      );
    }

    return (
      <div style={style} key={i}>
        {item.string || item}
      </div>
    );
  };

  getScrollToLinks = (item) => {
    const allBlocks = this.props.dataObject.pageObjectsArray.find(
      (e) => e.menuName === this.props.currentPage
    ).blocksArray;
    var array = [];
    for (var i = 0; i < allBlocks.length; i++) {
      if (allBlocks[i].isScrollTo)
        array.push(
          this.getButton(item, {
            label: allBlocks[i].id,
            scrollLink: allBlocks[i].id,
          })
        );
    }
    return array;
  };

  getButton = (item, e) => {
    const { isIOS, isAndroid } = this.props;
    const style = {
      flex: 1,
      backgroundColor: item.color || this.props.dataObject.highlightColor,
      color:
        item.textColor ||
        TextColorFromBackgroundColor(
          item.color || this.props.dataObject.highlightColor
        ),
      fontSize: 18,
      height: 60,
      width: item.maxDimensions || 250,
      maxWidth: item.maxDimensions,
      maxHeight: item.maxDimensions,
      minWidth: 130,
      cursor: "pointer",
      margin: 10,
      minHeight: 60,
      padding: "0 10px",
      borderRadius: item.roundedCorners ? 10 : 0,
    };
    if (item.border) style.border = "solid " + item.border;
    if (e.link)
      return (
        <a
          id={"button " + e.label}
          style={style}
          href={e.link}
          className="flexCenter"
          target={GetHrefTarget(e, isIOS, isAndroid)}
          key={e.label}
        >
          {e.label}
        </a>
      );
    if (e.scrollLink)
      return (
        <div
          id={"button " + e.label}
          style={style}
          onClick={this.props.handleScroll.bind(this, e.scrollLink)}
          className="flexCenter"
          key={e.label}
        >
          {CapitalizeFirstLetters(e.label)}
        </div>
      );
  };

  copyOrConnectPopUp = (string, style) => {
    var newStyle = Object.assign({}, style);
    newStyle.padding = 0;
    newStyle.paddingBottom = 0;
    newStyle.fontSize = 20;
    newStyle.color = "black";
    this.props.togglePopUpContent([
      <div
        style={{
          border: "1px solid black",
          borderRadius: 5,
          backgroundColor: "white",
          overflow: "hidden",
        }}
      >
        <div
          className="flexColumn flexCenter"
          style={{ backgroundColor: "black", padding: "20px 40px 20px 40px" }}
        >
          <div style={{ ...newStyle, color: "white" }}>{string}</div>
        </div>

        <div className="flexRow flexCenter">
          <div
            className="flexColumn flexCenter pointer"
            onClick={this.handleCopyOrConnectPopUpPress.bind(this, {
              string,
              action: "copy",
              newStyle,
            })}
            style={{
              flex: 1,
              backgroundColor: "white",
              padding: 20,
              borderRight: "solid 2px black",
            }}
          >
            <img
              src={IconList("copy")}
              alt="copy to clipboard"
              style={{ height: 50, width: 50, padding: "0 20px 0 20px" }}
            />
          </div>

          <div
            className="flexColumn flexCenter pointer"
            onClick={this.handleCopyOrConnectPopUpPress.bind(this, {
              string,
              newStyle,
            })}
            style={{
              flex: 1,
              backgroundColor: "white",
              padding: 20,
              borderLeft: "solid 2px black",
            }}
          >
            <img
              src={IconList(string.includes("@") ? "mail" : "phone")}
              alt={
                string.includes("@")
                  ? "send email to " + string
                  : "make phone call to " + string
              }
              style={{ height: 50, width: 50, padding: "0 20px 0 20px" }}
            />
          </div>
        </div>
      </div>,
    ]);
  };

  handleCopyOrConnectPopUpPress = ({ string, action, newStyle }) => {
    if (action === "copy") {
      navigator.clipboard.writeText(string);
      this.props.togglePopUpContent(
        <div
          style={{
            border: "1px solid white",
            borderRadius: 5,
            backgroundColor: "white",
            padding: 40,
            transition: "all 0.3s linear",
            opacity: this.state.copyOrConnectPopUpOpacity,
          }}
        >
          <div style={newStyle}>
            {string + " " + Dictionary("copied to clipboard")}
          </div>
        </div>
      );
      setTimeout(() => {
        this.foo();
      }, 1000);
    } else if (string.includes("@")) {
      window.open("mailto:" + string);
      this.clearPopUp();
    } else {
      window.open("tel:" + string);
      this.clearPopUp();
    }
  };

  foo = () => {
    this.setState({ copyOrConnectPopUpOpacity: 0 });
    setTimeout(() => {
      this.clearPopUp();
    }, 1000);
  };

  clearPopUp = () => {
    this.props.togglePopUpContent();
  };

  getFontSize = (fontSize) => {
    const { isPhone, isIOS } = this.props;
    if (fontSize === "giant") return isPhone ? 40 : 60;
    if (fontSize === "xl") return isPhone ? 30 : 40;
    if (fontSize === "large") return isPhone ? 20 : 30;
    if (fontSize === "medium") return isPhone ? 18 : 22;
    if (fontSize === "small") return 14;
    return isIOS ? 14 : 16;
  };

  // getRowItemWidth = ( itemCount, sidePadding = null ) => {
  //   const { isPhone, block, windowWidth, paddingSingle } = this.props;
  //   const innerWidth = windowWidth - ( paddingSingle*2 );
  //   console.log(innerWidth);
  //   if ( isPhone ) {
  //     var width = innerWidth/2;
  //     if ( sidePadding ) width -= ( sidePadding*2 );
  //     return width
  //   }
  //   return 250
  // }

  getBlockWidth = () => {
    const { isPhone, block, windowWidth } = this.props;
    if (isPhone || !block.widthFactor || block.widthFactor === 1)
      return windowWidth;
    return windowWidth * block.widthFactor;
  };

  buildContentArray = (contentArray) => {
    var displayArray = [];
    for (var i = 0; i < contentArray.length; i++) {
      const item = contentArray[i];
      if (item && (!this.props.isPhone || !item.hideOnPhone)) {
        displayArray.push(
          this.getDisplayItem({ item, i, itemCount: contentArray.length - 1 })
        );
      }
    }
    return displayArray;
  };

  getFooterMenu = () => {
    const { pageObjectsArray } = this.props.dataObject;
    const footerMenuBlocks = pageObjectsArray.filter((e) => e.inFooterMenu);
    if (footerMenuBlocks.length === 0) return null;
    var array = [];
    for (var i = 0; i < footerMenuBlocks.length; i++) {
      const { slug, menuName } = footerMenuBlocks[i];
      array.push(
        <a
          href={"/" + slug}
          style={{
            color: "white",
            fontSize: 11,
            paddingRight: i < footerMenuBlocks.length - 1 ? 30 : 0,
          }}
        >
          {menuName}
        </a>
      );
    }
    return (
      <div
        className="flexRow flexSpaceEvenly flexWrap"
        style={{ marginTop: 10 }}
      >
        {array}
      </div>
    );
  };

  render() {
    const { block, isPhone, computedHeight, windowHeight } = this.props;
    var paddingSingle = this.props.paddingSingle;
    var paddingArray = null;

    if (block.padding && !Array.isArray(block.padding) && !isPhone) {
      paddingSingle = block.padding;
    } else if (
      block.paddingPhone &&
      !Array.isArray(block.paddingPhone) &&
      isPhone
    ) {
      paddingSingle = block.paddingPhone;
    } else if (block.padding && !isPhone) {
      paddingArray = block.padding;
    } else if (block.paddingPhone && isPhone) {
      paddingArray = block.paddingPhone;
    }

    return (
      <Element
        name={block.id}
        className={
          this.props.rowClass +
          " " +
          (this.props.isFooter || block.centerContent
            ? "flexCenter"
            : "flexSpaceEvenly") +
          " contentBlockOuter flexColumn relative"
        }
        style={{
          height: computedHeight,
          minHeight: block.minHeightFactor
            ? windowHeight * block.minHeightFactor
            : null,
          width: this.getBlockWidth(),
          overflow: "hidden",
          backgroundColor: this.backgroundColor,
          position: "relative",
          paddingTop: paddingArray ? paddingArray[0] : paddingSingle,
          paddingRight: paddingArray ? paddingArray[1] : paddingSingle,
          paddingBottom: paddingArray ? paddingArray[2] : paddingSingle,
          paddingLeft: paddingArray ? paddingArray[3] : paddingSingle,
        }}
      >
        {block.contentArray && this.buildContentArray(block.contentArray)}

        {block.isScrollTo && (
          <img
            src={IconList(
              TextColorFromBackgroundColor(this.backgroundColor) === "white"
                ? "back_to_top_white"
                : "back_to_top"
            )}
            className="pointer"
            onClick={this.props.handleScroll.bind(this, "top")}
            style={{
              position: "absolute",
              top: 15,
              left: 15,
              height: 25,
              opacity: 0.3,
            }}
            alt="back to top button"
          />
        )}

        {this.props.isFooter && this.getFooterMenu()}
      </Element>
    );
  }
}
