import React from 'react';
import { EnglishNA } from './EnglishNA';

export const StringToDivsAsLineBreaks = ( string ) => {
	if ( string.length < 6 ) return string;
	const array = string.split(" ");

	if ( string.length < 40 ) {
		const middleIndex = Math.ceil( array.length/2 );
		return [
			<div key={1}>{ stringGluer( array, 0, middleIndex-1 ) }</div>,
			<div key={2}>{ stringGluer( array, middleIndex, array.length-1 ) }</div>,
		];
	}
	return string
}

const stringGluer = ( array, startIndex, endIndex ) => {
	var string = "";
	for (var i = startIndex; i <= endIndex; i++) {
		string += array[i];
		if ( i < endIndex ) string += " ";
	}
	return string;
}

export const GetHrefTarget = ( item, isIOS, isAndroid ) => {
  if ( !item || !item.link || isIOS || isAndroid ) return "";
  if ( item.link.substring(0,3) === "tel" ) return "";
  return "blank";
}

export const Dictionary = ( string, index = null ) => {
	var newString = ( index || index === 0 ) ? EnglishNA[ string ][index] : EnglishNA[ string ];
	return newString || string;
}

export const IconList = ( string ) => {
	if ( !string ) console.log( "no string" );
	return "https://willow-web-assets.s3.ca-central-1.amazonaws.com/icons/" + string + ".png";
}

export const CapitalizeFirstLetters = ( string ) => {
	const words = string.split(" ");
	for (var i = 0; i < words.length; i++) {
		words[i] = words[i][0].toUpperCase() + words[i].substr(1);
	}
	return words.join(" ");
}

export const TextColorFromBackgroundColor = ( color ) => {
	var array = []
	// console.error("input: " + color);
	if ( color.substr(0,1) === "#" ) {
		array = hexToRGB(color)
	} else if ( color.substr(0,3) === "rgb" ) {
		array = color.split("(")[1].split(")")[0].split(",");
	} else {
		array = hexToRGB( colourNameToHex(color) )
	}
  // http://www.w3.org/TR/AERT#color-contrast
  const brightness = Math.round(((parseInt(array[0]) * 299) +
                      (parseInt(array[1]) * 587) +
                      (parseInt(array[2]) * 114)) / 1000);
  const textColor = (brightness > 90) ? 'black' : 'white'
  // console.error("output: " + textColor );
  return textColor
  // return 'rgb(' + array[0] + ',' + array[1] + ',' + array[2] + ')';
}

const hexToRGB = (h) => {
  let r = 0, g = 0, b = 0;
  // 3 digits
  if (h.length === 4) {
    r = "0x" + h[1] + h[1];
    g = "0x" + h[2] + h[2];
    b = "0x" + h[3] + h[3];
  // 6 digits
  } else if (h.length === 7) {
    r = "0x" + h[1] + h[2];
    g = "0x" + h[3] + h[4];
    b = "0x" + h[5] + h[6];
  }
  return [r,g,b]
}

const colourNameToHex = (colour) => {
  var colours = {"aliceblue":"#f0f8ff","antiquewhite":"#faebd7","aqua":"#00ffff","aquamarine":"#7fffd4","azure":"#f0ffff",
  "beige":"#f5f5dc","bisque":"#ffe4c4","black":"#000000","blanchedalmond":"#ffebcd","blue":"#0000ff","blueviolet":"#8a2be2","brown":"#a52a2a","burlywood":"#deb887",
  "cadetblue":"#5f9ea0","chartreuse":"#7fff00","chocolate":"#d2691e","coral":"#ff7f50","cornflowerblue":"#6495ed","cornsilk":"#fff8dc","crimson":"#dc143c","cyan":"#00ffff",
  "darkblue":"#00008b","darkcyan":"#008b8b","darkgoldenrod":"#b8860b","darkgray":"#a9a9a9","darkgreen":"#006400","darkkhaki":"#bdb76b","darkmagenta":"#8b008b","darkolivegreen":"#556b2f",
  "darkorange":"#ff8c00","darkorchid":"#9932cc","darkred":"#8b0000","darksalmon":"#e9967a","darkseagreen":"#8fbc8f","darkslateblue":"#483d8b","darkslategray":"#2f4f4f","darkturquoise":"#00ced1",
  "darkviolet":"#9400d3","deeppink":"#ff1493","deepskyblue":"#00bfff","dimgray":"#696969","dodgerblue":"#1e90ff",
  "firebrick":"#b22222","floralwhite":"#fffaf0","forestgreen":"#228b22","fuchsia":"#ff00ff",
  "gainsboro":"#dcdcdc","ghostwhite":"#f8f8ff","gold":"#ffd700","goldenrod":"#daa520","gray":"#808080","green":"#008000","greenyellow":"#adff2f",
  "honeydew":"#f0fff0","hotpink":"#ff69b4",
  "indianred ":"#cd5c5c","indigo":"#4b0082","ivory":"#fffff0","khaki":"#f0e68c",
  "lavender":"#e6e6fa","lavenderblush":"#fff0f5","lawngreen":"#7cfc00","lemonchiffon":"#fffacd","lightblue":"#add8e6","lightcoral":"#f08080","lightcyan":"#e0ffff","lightgoldenrodyellow":"#fafad2",
  "lightgrey":"#d3d3d3","lightgreen":"#90ee90","lightpink":"#ffb6c1","lightsalmon":"#ffa07a","lightseagreen":"#20b2aa","lightskyblue":"#87cefa","lightslategray":"#778899","lightsteelblue":"#b0c4de",
  "lightyellow":"#ffffe0","lime":"#00ff00","limegreen":"#32cd32","linen":"#faf0e6",
  "magenta":"#ff00ff","maroon":"#800000","mediumaquamarine":"#66cdaa","mediumblue":"#0000cd","mediumorchid":"#ba55d3","mediumpurple":"#9370d8","mediumseagreen":"#3cb371","mediumslateblue":"#7b68ee",
  "mediumspringgreen":"#00fa9a","mediumturquoise":"#48d1cc","mediumvioletred":"#c71585","midnightblue":"#191970","mintcream":"#f5fffa","mistyrose":"#ffe4e1","moccasin":"#ffe4b5",
  "navajowhite":"#ffdead","navy":"#000080",
  "oldlace":"#fdf5e6","olive":"#808000","olivedrab":"#6b8e23","orange":"#ffa500","orangered":"#ff4500","orchid":"#da70d6",
  "palegoldenrod":"#eee8aa","palegreen":"#98fb98","paleturquoise":"#afeeee","palevioletred":"#d87093","papayawhip":"#ffefd5","peachpuff":"#ffdab9","peru":"#cd853f","pink":"#ffc0cb","plum":"#dda0dd","powderblue":"#b0e0e6","purple":"#800080",
  "rebeccapurple":"#663399","red":"#ff0000","rosybrown":"#bc8f8f","royalblue":"#4169e1",
  "saddlebrown":"#8b4513","salmon":"#fa8072","sandybrown":"#f4a460","seagreen":"#2e8b57","seashell":"#fff5ee","sienna":"#a0522d","silver":"#c0c0c0","skyblue":"#87ceeb","slateblue":"#6a5acd","slategray":"#708090","snow":"#fffafa","springgreen":"#00ff7f","steelblue":"#4682b4",
  "tan":"#d2b48c","teal":"#008080","thistle":"#d8bfd8","tomato":"#ff6347","turquoise":"#40e0d0",
  "violet":"#ee82ee",
  "wheat":"#f5deb3","white":"#ffffff","whitesmoke":"#f5f5f5",
  "yellow":"#ffff00","yellowgreen":"#9acd32"};

  if (typeof colours[colour.toLowerCase()] != 'undefined')
      return colours[colour.toLowerCase()];

  return false;
}