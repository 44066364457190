import React from "react";

import { Helmet } from "react-helmet";
import { Element, scroller } from "react-scroll";

import ContentBlock from "./contentBlock";
import PhoneContactBar from "./phoneContactBar";
import { StringToDivsAsLineBreaks } from "./common";

import mckitrickJson from "./json/mckitrick.json";
import willowJson from "./json/willow.json";
import bestcoJson from "./json/bestco.json";
import fischlerJson from "./json/fischler.json";
import kellyJson from "./json/kelly.json";
import kayoJson from "./json/kayo.json";
import cardiffJson from "./json/cardiff.json";

import ReactGA from "react-ga";
ReactGA.initialize(willowJson.googleAnalyticsId, { debug: false });

export default class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: 0,
      windowHeight: 0,
      isPhone: true,
      isIOS: false,
      isAndroid: false,
      currentPage: "Home",
      rowHeightsRerunCount: 0,
      dataObject: kayoJson,
      // dataObject: willowJson,
      // dataObject: cardiffJson,
      // dataObject: mckitrickJson,
      // dataObject: bestcoJson,
      // dataObject: fischlerJson,
      // dataObject: cardiffJson,
      dataObjectLateTwoSeconds: false,
      loadingMessage: "Please wait, your information is loading ...",

      popUpContent: null,
    };
    this.multiBlockRowHeightValuesHash = {};
    this.currentMultiBlockRowIndex = 0;
    this.keyGenValue = 0;
  }

  componentDidMount = () => {
    if (!this.state.dataObject) {
      this.getWebsiteData();
    } else {
      this.setPageFromAddressBar();
    }
    window.addEventListener("resize", this.setDimensions);
    var isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    if (navigator.maxTouchPoints > 4) isIOS = true;
    const isAndroid = /Android/.test(navigator.userAgent);
    this.setState({ isIOS, isAndroid });
    this.setDimensions();
    if (this.state.dataObject) this.setBodyStyles(this.state.dataObject);
    setTimeout(() => this.setState({ dataObjectLateTwoSeconds: true }), 2000);
  };

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.setDimensions);
  };

  async getWebsiteData() {
    var domainNameWithTLD = window.location.href.split("/")[2];
    if (domainNameWithTLD.substring(0, 9) === "localhost")
      domainNameWithTLD = "willowpractice.com";
    console.log(domainNameWithTLD);
    const foo = fetch(
      "https://" + domainNameWithTLD + "/api/v1/web/getWebsite",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ domainNameWithTLD }),
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && !data.err) {
          this.setState({ dataObject: data });
          this.setBodyStyles(data);
          this.setPageFromAddressBar();
          this.setDimensions(); // rerun due to delay in retrieving data
        } else {
          this.setState({
            loadingMessage:
              "We ran into a problem loading your information. Please try again.",
          });
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  }

  setBodyStyles = (data) => {
    if (data.fontFamily) document.body.style.fontFamily = data.fontFamily;
  };

  setPageFromAddressBar = () => {
    var pageName = "";
    const addressArray = window.location.href.split("/");
    for (var i = addressArray.length - 1; i >= 0; i--) {
      if (addressArray[i].length > 0) {
        pageName = addressArray[i];
        break;
      }
    }

    if (pageName === "customer") {
      window.location.href = "https://customer.willowpractice.com";
    } else {
      this.setPageVariables({ slug: pageName });
    }
  };

  setPageVariables = ({ slug, menuName }) => {
    console.log(this.state.dataObject.pageObjectsArray.length);
    if (this.state.dataObject.pageObjectsArray.length < 2) return;
    const { dataObject } = this.state;
    const filteredArray = dataObject.pageObjectsArray.filter(
      (e) => e.menuName === menuName || e.slug === slug
    );
    const pageObject = filteredArray[0] || dataObject.pageObjectsArray[0];
    const browserTitle =
      pageObject.browserTitle ||
      pageObject.menuName + " - " + dataObject.siteTitle;
    window.history.pushState(null, browserTitle, "/" + pageObject.slug);
    document.title = browserTitle;
    this.setState({ currentPage: pageObject.menuName });
  };

  setDimensions = () => {
    var windowWidth = window.innerWidth;
    var windowHeight = window.innerHeight;
    if (this.state.isIOS) {
      windowWidth = window.outerWidth;
      windowHeight = window.outerHeight;
    } else if (this.state.isAndroid) {
      windowWidth = window.screen.width;
      windowHeight = window.screen.height;
    }
    const isPhone = windowWidth < 600;
    this.setState({ windowHeight, windowWidth, isPhone });

    setTimeout(() => this.setRowHeights(), 10);
  };

  setRowHeights = () => {
    // console.log("setting row heights");
    for (
      var i = 0;
      i < Object.keys(this.multiBlockRowHeightValuesHash).length;
      i++
    ) {
      // each row
      const index = Object.keys(this.multiBlockRowHeightValuesHash)[i];
      const blocksInRow = document.getElementsByClassName("row" + index);
      var maxHeight = this.state.isPhone ? 200 : 400;

      for (var p = 0; p < blocksInRow.length; p++) {
        // each block in the row
        const height = blocksInRow[p].getBoundingClientRect().height;
        if (height > maxHeight) maxHeight = height;
      }

      if (maxHeight !== this.multiBlockRowHeightValuesHash[index]) {
        const newHash = Object.assign({}, this.multiBlockRowHeightValuesHash);
        newHash[index] = maxHeight;
        this.multiBlockRowHeightValuesHash = newHash;
      }
    }
    this.setState({
      rowHeightsRerunCount: this.state.rowHeightsRerunCount + 1,
    });
    // console.log( "rowHeightsRerunCount: " + this.state.rowHeightsRerunCount);
  };

  getMainLinksArray = () => {
    var array = [];
    for (var i = 0; i < this.state.dataObject.pageObjectsArray.length; i++) {
      const page = this.state.dataObject.pageObjectsArray[i];
      if (page.menuName === "Home") continue;
      if (
        (!page.hideFromTopMenu && !page.inFooterMenu) ||
        page.menuName === this.state.currentPage
      )
        array.push(page.menuName);
    }
    return array;
  };

  handleMenuItemClick = (name) => {
    this.setState({ currentPage: name });
    this.multiBlockRowHeightValuesHash = {};
    this.setDimensions();
    window.scrollTo(0, 0);
    this.setPageVariables({ menuName: name });
  };

  handleScroll = (e) => {
    // console.error("handleScroll"); console.error(e);
    scroller.scrollTo(e, {
      duration: 100,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  bar = () => {
    // console.log("bar");
    // console.log(window.location.pathname + window.location.search);
    ReactGA.pageview(window.location.pathname + window.location.search);
  };

  buildPage = (e) => {
    const { isPhone } = this.state;
    if (!e.blocksArray) return null;

    if (this.state.dataObject.googleAnalyticsId) this.bar();

    var displayArray = [];
    var multiBlockDisplayRow = [];
    var multiBlockWidthArray = [];
    var phoneBlockToBeSwapped = null;

    for (var i = 0; i < e.blocksArray.length; i++) {
      const block = e.blocksArray[i];

      // skip block
      if (isPhone && block.hideOnPhone) continue;
      if (!isPhone && block.showOnlyOnPhone) continue;

      // no multi-block rows on phone
      if (isPhone) {
        const displayBlock = this.getSingleBlock(
          block,
          i,
          this.getHeightFixed(block)
        );
        if (block.swapNextOnPhone) {
          console.error("swapNextOnPhone");
          phoneBlockToBeSwapped = displayBlock;
        } else {
          displayArray.push(displayBlock);
          if (phoneBlockToBeSwapped) {
            displayArray.push(phoneBlockToBeSwapped);
            phoneBlockToBeSwapped = null;
          }
        }
        continue;
      }

      // block is less than full width, add there is room for the block
      if (
        block.widthFactor < 1 &&
        multiBlockWidthArray.length > 0 &&
        multiBlockWidthArray.reduce((a, b) => a + b, 0) + block.widthFactor <= 1
      ) {
        multiBlockDisplayRow.push(
          this.getSingleBlock(
            block,
            i,
            this.getHeightVariable(),
            this.currentMultiBlockRowIndex
          )
        );
        multiBlockWidthArray.push(block.widthFactor);

        // is last block, so push multi block row to display and clear arrays
        if (multiBlockWidthArray.length > 0 && i === e.blocksArray.length - 1) {
          displayArray.push(multiBlockDisplayRow);
          multiBlockDisplayRow = [];
          multiBlockWidthArray = [];
          this.currentMultiBlockRowIndex += 1;
        }
      } else {
        // existing blocks in multi block row variable, add multi blocks to display array
        if (multiBlockWidthArray.length > 0) {
          displayArray.push(multiBlockDisplayRow);
          multiBlockDisplayRow = [];
          multiBlockWidthArray = [];
          this.currentMultiBlockRowIndex += 1;
        }

        // full width block, add directly to display array
        if (block.widthFactor === 1) {
          displayArray.push(
            this.getSingleBlock(block, i, this.getHeightFixed(block))
          );

          // add current block to existing multi block row variable
        } else {
          multiBlockDisplayRow.push(
            this.getSingleBlock(
              block,
              i,
              this.getHeightVariable(),
              this.currentMultiBlockRowIndex
            )
          );
          multiBlockWidthArray.push(block.widthFactor);
        }
      }
    }
    this.currentMultiBlockRowIndex = 0;
    return displayArray;
  };

  getSingleBlock = (block, i, height, index = "Full") => {
    const { isPhone, windowWidth, windowHeight, isIOS, isAndroid } = this.state;
    const paddingSingle = isPhone ? 30 : 60;

    return (
      <ContentBlock
        windowHeight={windowHeight}
        windowWidth={windowWidth}
        isPhone={isPhone}
        block={block}
        isIOS={isIOS}
        isAndroid={isAndroid}
        computedHeight={height}
        rowClass={"row" + index}
        isFirstBlock={i === 0}
        dataObject={this.state.dataObject}
        currentPage={this.state.currentPage}
        paddingSingle={paddingSingle}
        handleScroll={this.handleScroll}
        togglePopUpContent={(e) => this.setState({ popUpContent: e })}
        key={i}
      />
    );
  };

  getHeightVariable = () => {
    if (
      this.currentMultiBlockRowIndex.toString() in
      this.multiBlockRowHeightValuesHash
    )
      return this.multiBlockRowHeightValuesHash[this.currentMultiBlockRowIndex];

    const newMultiBlockRowHeightValuesHash = Object.assign(
      {},
      this.multiBlockRowHeightValuesHash
    );
    newMultiBlockRowHeightValuesHash[this.currentMultiBlockRowIndex] = null;
    this.multiBlockRowHeightValuesHash = newMultiBlockRowHeightValuesHash;
    return this.multiBlockRowHeightValuesHash[this.currentMultiBlockRowIndex];
  };

  getHeightFixed = (block) => {
    const { isPhone, windowHeight } = this.state;
    if (block.height) return block.height;
    if (!block.heightFactor && (!isPhone || !block.heightFactorPhone))
      return null;
    if (isPhone && block.heightFactorPhone === "unlimited") {
      if (
        block.contentArray.length === 1 &&
        block.contentArray[0].type === "imageBackground"
      )
        return 200;
      return null;
    }
    var heightFactor = block.heightFactor;
    if (isPhone && block.heightFactorPhone)
      heightFactor = block.heightFactorPhone;
    var height = heightFactor ? windowHeight * heightFactor : windowHeight / 2;
    if (!isPhone && height < 300) height = 300;
    if (isPhone && height < 150) height = 150;
    return height;
  };

  getPopUpOverlay = () => {
    return (
      <div
        className="fillParent flexColumn flexCenter"
        style={{
          backgroundColor: "rgba( 0, 0, 0, 0.8)",
          zIndex: 10,
          position: "fixed",
        }}
      >
        <div style={{ zIndex: 12 }}>{this.state.popUpContent}</div>
        <div
          className="fillParent"
          onClick={() => this.setState({ popUpContent: null })}
        />
      </div>
    );
  };

  loadingScreen = () => {
    return (
      <div
        className="flexCenter flexColumn"
        style={{
          flex: 1,
          backgroundColor: "black",
          color: "white",
          height: this.state.windowHeight,
          width: this.state.windowWidth,
        }}
      >
        <div
          className="flexCenter flexColumn"
          style={{
            width: this.state.windowWidth / 2,
            transition: "opacity 2s",
            opacity: this.state.dataObjectLateTwoSeconds ? 1 : 0,
          }}
        >
          <div className="sk-grid" style={{ height: 60, width: 60 }}>
            {[...Array(9).keys()].map((e) => (
              <div className="sk-grid-cube" key={e}></div>
            ))}
          </div>
          <div style={{ width: "50%", marginTop: 40 }}>
            {this.state.loadingMessage}
          </div>
        </div>
      </div>
    );
  };

  getMetaTags = () => {
    const { metaObject } = this.state.dataObject;
    var displayArray = [
      <meta name="theme-color" content="#000000" key={this.keyGen()} />,
      <title key={this.keyGen()}>{metaObject.title}</title>,
      <link
        rel="canonical"
        href={metaObject.canonicalUrl}
        key={this.keyGen()}
      />,
      <meta
        name="description"
        content={metaObject.description}
        key={this.keyGen()}
      />,

      // Schema.org
      <meta itemprop="name" content={metaObject.title} key={this.keyGen()} />,
      <meta
        itemprop="description"
        content={metaObject.description}
        key={this.keyGen()}
      />,
      <meta
        itemprop="image"
        content={metaObject.metaImage}
        key={this.keyGen()}
      />,

      //Twitter
      <meta name="twitter:card" content="summary" key={this.keyGen()} />,

      // Facebook
      <meta
        property="og:title"
        content={metaObject.ogTitle || metaObject.title}
        key={this.keyGen()}
      />,
      <meta
        property="og:description"
        content={metaObject.ogDescription || metaObject.description}
        key={this.keyGen()}
      />,
      <meta
        property="og:url"
        content={metaObject.ogUrl || metaObject.canonicalUrl}
        key={this.keyGen()}
      />,
      <meta
        property="og:image"
        content={metaObject.metaImage}
        key={this.keyGen()}
      />,
    ];
    if (metaObject.array) {
      for (var i = 0; i < metaObject.array.length; i++) {
        const item = metaObject.array[i];
        if (item.rel) {
          displayArray.push(
            <link rel={item.rel} href={item.href} type={item.type} key={i} />
          );
        } else if (item.name) {
          displayArray.push(
            <meta name={item.name} content={item.content} key={i} />
          );
        } else if (item.property) {
          displayArray.push(
            <meta property={item.property} content={item.content} key={i} />
          );
        }
      }
    }
    return <Helmet>{displayArray}</Helmet>;
  };

  keyGen = () => {
    this.keyGenValue += 1;
    return this.keyGenValue;
  };

  render() {
    const {
      isPhone,
      isIOS,
      isAndroid,
      windowWidth,
      windowHeight,
      currentPage,
      dataObject,
    } = this.state;
    const wideScreen = windowWidth > 900;

    if (!dataObject) return this.loadingScreen();

    const linksArray = this.getMainLinksArray();

    return (
      <div
        id="siteOuterHome"
        className="flexCenter relative"
        style={{
          flexDirection: isPhone ? "column" : "row",
          flexWrap: isPhone ? "nowrap" : "wrap",
          paddingBottom: isPhone ? 80 : 0,
          backgroundColor: dataObject.footerObject
            ? dataObject.footerObject.backgroundColor
            : "black",
        }}
      >
        {dataObject.metaObject && this.getMetaTags()}
        {this.state.popUpContent && this.getPopUpOverlay()}

        {/* Site Header */}

        {!isPhone && linksArray.length > 0 && (
          <div
            id="siteHeader"
            className="flexCenter flexRow"
            style={{
              zIndex: 2,
              position: "absolute",
              top: 0,
              width: windowWidth,
              padding: isPhone ? "20px 0px" : "30px 0px",
              backgroundColor: dataObject.headerBackgroundColor,
            }}
          >
            <div
              className="flexStart flexRow"
              onClick={() => this.handleMenuItemClick("Home")}
              style={{
                flex: 2,
                fontSize: 25,
                color: "white",
                marginLeft: isPhone ? 20 : wideScreen ? 50 : 30,
                cursor: "pointer",
              }}
            >
              {dataObject.logoUrl && (
                <img
                  alt="logo"
                  src={dataObject.logoUrl}
                  style={{ height: 50, marginRight: 10 }}
                />
              )}
              <div
                style={{
                  borderBottom:
                    "Home" === currentPage ? "1px solid white" : null,
                }}
              >
                {dataObject.siteTitle}
              </div>
            </div>

            <div
              className="flexCenter flexRow"
              style={{
                flex: 4,
                fontSize: 18,
                justifyContent: "space-between",
                paddingRight: isPhone ? 20 : 50,
                color: "white",
              }}
            >
              {linksArray.map((e) => (
                <div
                  onClick={() => this.handleMenuItemClick(e)}
                  className="flexColumn flexCenter"
                  style={{
                    flex: 1,
                    cursor: "pointer",
                    borderBottom: e === currentPage ? "1px solid white" : null,
                    whiteSpace: "nowrap",
                    fontSize: wideScreen ? null : 12,
                    marginRight: wideScreen ? 25 : 15,
                    height: wideScreen ? 45 : 28,
                    maxWidth: 400,
                  }}
                  key={e}
                >
                  {StringToDivsAsLineBreaks(e)}
                </div>
              ))}
            </div>
          </div>
        )}

        <Element name="top"></Element>
        {/* Build Page */}

        {this.buildPage(
          dataObject.pageObjectsArray.find((e) => e.menuName === currentPage)
        )}

        {/* Footer */}

        {dataObject.footerObject && (
          <ContentBlock
            windowHeight={windowHeight}
            windowWidth={windowWidth}
            isPhone={isPhone}
            isIOS={isIOS}
            isAndroid={isAndroid}
            dataObject={dataObject}
            block={dataObject.footerObject}
            computedHeight={this.getHeightFixed(dataObject.footerObject)}
            togglePopUpContent={(e) => this.setState({ popUpContent: e })}
            isFooter={true}
          />
        )}

        {/* Phone Contact Bar */}

        {isPhone && (
          <PhoneContactBar
            dataObject={dataObject}
            handleMenuItemClick={this.handleMenuItemClick}
            mainLinksArray={this.getMainLinksArray()}
            windowHeight={windowHeight}
            windowWidth={windowWidth}
            isIOS={isIOS}
            isAndroid={isAndroid}
          />
        )}
      </div>
    );
  }
}
