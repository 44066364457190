export const EnglishNA = {

	DaysOfWeekArray: [ "Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday" ],
	DaysOfWeekShortArray: [ "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat" ],
	MonthsNamesArray: [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ],
	MonthsNamesShortArray: [ "Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec" ],
	Seconds: "Seconds", seconds: "seconds", second: "second", secs: "secs",
	Minutes: "Minutes", minutes: "minutes", minute: "minute", mins: "mins",
	Hours: "Hours", hours: "hours", hour: "hour", hr: "hr",
	Days: "Days", days: "days", day: "day",
	DaysAgo: "Days Ago",
	DaysFromNow: "Days From Now",
	Weeks: "Weeks", weeks: "weeks", week: "week",
	WeeksAgo: "Weeks Ago",
	OneWeekAgo: "One Week Ago",
	OneWeekFromNow: "One Week From Now",
	WeeksFromNow: "Weeks From Now",
	EveryDayWhenOpen: "Every Day When Open",
	ShowEachDay: "Show Each Day",
	Starts: "Starts",
	Ended: "Ended",
	Select: "Select", Selected: "Selected",
	Guests: "Guests",
	GuestLoading: "Guest Loading",
	ResetGuest: "Reset Guest",
	ExistingGuests: "Existing Guests",
	ReturnToCurrentService: "Return To Current Service",

	Today: "Today", TodayAt: "Today at",
	Tomorrow: "Tomorrow", TomorrowAt: "Tomorrow at",
	Yesterday: "Yesterday",
	Last: "Last",

	Password: "Password", password: "password",
	Confirm: "Confirm",
	GoBack: "Back",
	Cancelled: "Cancelled", Cancel: "Cancel",
	PleaseCheckIn: "Please Check In",
	Name: "Name",
	NoName: "No Name",
	Size: "Size",
	Capacity: "Capacity",
	Chairs: "Chairs",
	ServerSection: "Server Section",
	New: "New",
	Reservable: "Reservable",
	SelfBookable: "Self Bookable",
	CombinableGroup: "Combinable Group",
	Label: "Label",
	Tags: "Tags",
	GuestTags: "Guest Tags",
	LongSideInFeet: "Long Side (ft)",
	ShortSideInFeet: "Short Side (ft)",
	Shared: "Shared",
	NotShared: "Not Shared",
	NotDefault: "Not Default",
	MapSettings: "Map Settings",
	TableSettingsSideBar: "Table Settings",
	IsBookingMap: "Booking Map",
	MultipleTableSettingsSideBar: "Multiple Table Settings",
	BookingSettings: "Booking Settings",
	Number: "Number",
	TableNumber: "Table Number",
	TableName: "Table Name",
	Bar: "Bar",
	OptionalName: "Optional Name",
	Delete: "Delete",
	UnDelete: "Un-Delete",
	ResetData: "Reset Data",
	Notes: "Notes",
	FullNotes: "Full Notes",
	More: "More",
	hasTheStatus: "has the status",
	History: "History",
	Clear: "Clear",
	ChangeTables: "Change Tables",
	MultipleTables: "Multiple Tables",
	ImmediateSeating: "Immediate Seating",
	ShowCancelled: "Show Cancelled",
	HideCancelled: "Hide Cancelled",

	//Sign In
	Email: "Email", email: "email",
	EmailPlaceholder: "name@mail.com",
	RenewSignIn: "Renew Sign In",
	SignIn: "Sign In",
	SignOut: "Sign Out",
	SignInTimedOut: "Sign In Timed Out",
	RequestCode: "Request Code",
	SubmitCode: "Submit Code",
	EnterActivationCode: "Enter Activation Code",
	SendActivationCodeTo: "Send Activation Code to a registered email address, or get from another device.",
	OrGetActivationCode: "Or get an Activation Code from another device",
	CodeHasBeenSent: "Code Has Been Sent",
	CodeIsExpiredOrNotFound: "Code Is Expired Or Not Found",
	Submit: "Submit",
	password_invalid: "The email or password you entered is not valid",
	email_invalid: "The email or password you entered is not valid",
	token_expired: "Your session has expired. Please sign in again.",
	token_invalid: "Your account has been signed in on another device.",


	// Messaging
	and: "and",
	To: "To",
	From: "From",
	ForwardedMessage: "Forwarded Message", ForwardTo: "Forward To", Fwd: "Fwd",
	Date: "Date",
	Subject: "Subject", EnterSubject: "Enter Subject",
	hasIndicatedTheyDoNotWishToRecieve: "has indicated they do not wish to recieve",
	Send: "Send",
	SMS: "SMS",
	DoYouWantToSendThisMessageAnyway: "Do you want to send this message anyway",
	Reply: "Reply",
	MarkRead: "Mark Read",
	NewConversation: "New Conversation",
	NewMessage: "New Message", NewMessages: "New Messages",
	Messages: "Messages", SearchMessages: "Search Messages", EnterMessage: "Enter Message",
	Settings: "Settings",
	Read: "Read", ReadOn: "Read On", ReadBy: "Read By", ReadAt: "Read At",
	Sent: "Sent",
	delivered: "Delivered",
	Received: "Received",
	VoiceMailLink: "Voice Mail Link",
	ThisEmailAddressIsNotComplete: "This Email Address Is Not Complete",
	ThisMobilePhoneNumberIsTooShort: "This Mobile Phone Number Is Too Short",
	LoadOlderMessages: "Load Older Messages",
	CancelNewMessage: "Cancel New Message",
	AutoTranscribed: "[auto transcribed]",
	StaffEdited: "[staff edited]",
	EnterNotesFromEmail: "Enter notes from voicemail ...",
	EnterActionNote: "Enter action note ...",
	ActionNote: "Action Note",
	AddActionNote: "Add Action Note",
	noMessages: "no messages",
	PhoneCall: "Phone Call",
	ShowCalls: "Show Calls",
	VoiceMessage: "Voicemail Message",
	VoicemailText: "Voicemail & Text",
	AllMessagesCalls: "All Messages & Calls",
	ArchivedMessages: "Archived Messages",
	Archive: "Archive",

	spam: "Spam",
	Spam: "Spam",
	trash: "Trash",
	Trash: "Trash",
	inbox: "Inbox",
	Inbox: "Inbox",
	MoveToInbox: "Move To Inbox",
	ToInbox: "To Inbox",
	NotSpam: "Not Spam",

	ConnectionProblem: "Connection Problem - last connected:",
	"request call back": "Call Back Request",
	"join waitlist": "Join Waitlist Request",

	firstName: "First Name",
	middleNames: "Middle Name(s)",
	lastName: "Last Name",

}