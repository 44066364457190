import React from "react";
import { GetHrefTarget } from "./common";

import homeIcon from "./assets/home_icon.png";
import phoneIcon from "./assets/phone_icon.png";
import mailIcon from "./assets/mail_icon.png";
import mapIcon from "./assets/map_icon.png";

export default class PhoneContactBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showMenu: false,
      showPhone: false,
      showEmail: false,
    };
  }

  handleMenuItemClick = (title) => {
    this.setState({ showMenu: false, showEmail: false, showPhone: false });
    this.props.handleMenuItemClick(title);
  };

  clearOverlays = () => {
    this.setState({ showMenu: false, showEmail: false, showPhone: false });
  };

  handleBurgerClick = () => {
    this.setState({
      showMenu: !this.state.showMenu,
      showEmail: false,
      showPhone: false,
    });
  };

  handleEmailIconClick = () => {
    this.setState({
      showMenu: false,
      showEmail: !this.state.showEmail,
      showPhone: false,
    });
  };

  handlePhoneIconClick = () => {
    this.setState({
      showMenu: false,
      showEmail: false,
      showPhone: !this.state.showPhone,
    });
  };

  handleMapIconClick = () => {
    /* if we're on iOS, open in Apple Maps */
    if (
      navigator.platform.indexOf("iPhone") !== -1 ||
      navigator.platform.indexOf("iPad") !== -1 ||
      navigator.platform.indexOf("iPod") !== -1
    ) {
      window.open("maps://maps.google.com?q=" + this.convertAddressForQuery());
    } else {
      /* else use Google */
      window.open("https://maps.google.com?q=" + this.convertAddressForQuery());
    }
  };

  convertAddressForQuery = () => {
    var converted = this.props.dataObject.address;
    console.log(converted.replaceAll(" ", "%20"));
    return converted.replaceAll(" ", "%20");
  };

  render() {
    const { mainLinksArray, windowHeight, windowWidth, isAndroid, isIOS } =
      this.props;
    const {
      highlightColor,
      externalChatEnabled,
      emailArray,
      phoneArray,
      address,
    } = this.props.dataObject;
    const emailsToClick = emailArray && emailArray.length > 0;
    const phoneNumbersToClick = phoneArray && phoneArray.length > 0;
    if (
      !emailsToClick &&
      !phoneNumbersToClick &&
      (!mainLinksArray || mainLinksArray.length === 0)
    )
      return null;

    const { showEmail, showPhone, showMenu } = this.state;
    const horizontalBar = {
      width: 50,
      backgroundColor: highlightColor,
      borderRadius: 8,
      height: 10,
      marginBottom: 5,
    };
    const buttonStyle = {
      padding: "3px 3px 0px 3px",
      backgroundColor: highlightColor,
      borderRadius: 30,
      width: 57,
      height: 57,
      textDecoration: "none",
      cursor: "pointer",
    };
    const burgerButtonStyle = {
      backgroundColor: "black",
      width: "70%",
      color: "white",
      padding: "5px 20px",
      margin: 5,
    };

    return (
      <div
        style={{
          position: "fixed",
          bottom: showPhone || showEmail || showMenu ? 0 : -windowHeight,
          height: windowHeight,
          width: windowWidth,
          transition: "all ease-out .3s",
          backgroundColor: "rgba(256,256,256,0.8)",
          zIndex: 9,
        }}
      >
        <div
          style={{ height: windowHeight, width: windowWidth }}
          onClick={() =>
            this.setState({
              showMenu: false,
              showEmail: false,
              showPhone: false,
            })
          }
        />

        <div
          id="phoneDeviceIconRow"
          className="flexRow"
          style={{
            position: "fixed",
            left: 0,
            right: 0,
            bottom: 0,
            padding: externalChatEnabled
              ? "15px 80px 20px 0px"
              : "15px 0px 20px 0px",
            zIndex: 10,
            justifyContent: "space-evenly",
            backgroundColor: "rgba(256,256,256,0.9)",
            borderTop: "1px solid " + highlightColor,
          }}
        >
          {/* Bottom Bar Main Buttons */}

          {emailsToClick && (
            <div
              id="emailButton"
              onClick={this.handleEmailIconClick}
              className="flexColumn flexCenter"
              style={buttonStyle}
            >
              <img
                src={mailIcon}
                alt="mail icon"
                style={{ width: 34, height: 34 }}
              />
            </div>
          )}

          {phoneNumbersToClick && (
            <div
              id="callButton"
              onClick={this.handlePhoneIconClick}
              className="flexColumn flexCenter"
              style={buttonStyle}
            >
              <img
                src={phoneIcon}
                alt="phone icon"
                style={{ width: 40, height: 40 }}
              />
            </div>
          )}

          {address && (
            <div
              id="callButton"
              onClick={this.handleMapIconClick}
              className="flexColumn flexCenter"
              style={buttonStyle}
            >
              <img
                src={mapIcon}
                alt="navigate icon"
                style={{ width: 40, height: 40 }}
              />
            </div>
          )}

          {mainLinksArray.length > 0 && (
            <div
              id="burgerButton"
              className="flexColumn flexCenter"
              style={{ marginTop: 3 }}
              onClick={this.handleBurgerClick}
            >
              <div style={horizontalBar} />
              <div style={horizontalBar} />
              <div style={horizontalBar} />
            </div>
          )}

          {/* Burger Menu */}

          {showMenu && (
            <div
              id="menu"
              className="flexCenter"
              style={{
                flexDirection: "column",
                position: "absolute",
                bottom: showMenu ? 120 : -windowHeight,
                right: 0,
                width: windowWidth,
                fontSize: 16,
              }}
            >
              <div
                className="flexCenter"
                style={{ ...buttonStyle, ...burgerButtonStyle }}
                onClick={() => this.handleMenuItemClick("Home")}
              >
                <img
                  src={homeIcon}
                  alt="home link"
                  style={{ width: 35, height: 35 }}
                />
              </div>

              {mainLinksArray.map((title) => (
                <div
                  className="flexColumn flexCenter"
                  style={{ ...buttonStyle, ...burgerButtonStyle }}
                  onClick={() => this.handleMenuItemClick(title)}
                  key={title}
                >
                  {title}
                </div>
              ))}
            </div>
          )}

          {/* Phone Options */}

          <div
            className="flexCenter"
            style={{
              flexDirection: "column",
              position: "absolute",
              bottom: showPhone ? 120 : -windowHeight,
              right: 0,
              width: windowWidth,
              fontSize: 22,
            }}
          >
            {phoneArray &&
              phoneArray.map((e) => (
                <a
                  href={"tel:" + e}
                  target={GetHrefTarget({ link: "tel:" + e }, isIOS, isAndroid)}
                  onClick={this.clearOverlays}
                  className="flexColumn flexCenter"
                  style={{
                    ...buttonStyle,
                    width: null,
                    color: "white",
                    padding: "5px 40px",
                    flex: 1,
                    margin: 15,
                  }}
                  key={e}
                >
                  <div style={{ marginTop: 10 }}>{e}</div>
                  <div style={{ fontSize: 10 }}>Press To Dial</div>
                </a>
              ))}
          </div>

          {/* email Options */}

          <div
            className="flexCenter"
            style={{
              flexDirection: "column",
              position: "absolute",
              bottom: showEmail ? 120 : -windowHeight,
              right: 0,
              width: windowWidth,
              fontSize: 22,
            }}
          >
            {emailArray &&
              emailArray.map((e) => (
                <a
                  href={"mailto:" + e}
                  target={GetHrefTarget(
                    { link: "mailto:" + e },
                    isIOS,
                    isAndroid
                  )}
                  onClick={this.clearOverlays}
                  className="flexColumn flexCenter"
                  style={{
                    ...buttonStyle,
                    width: null,
                    color: "white",
                    padding: "5px 40px",
                    flex: 1,
                    margin: 15,
                  }}
                  key={e}
                >
                  <div style={{ marginTop: 10 }}>{e}</div>
                  <div style={{ fontSize: 10 }}>Press To Send</div>
                </a>
              ))}
          </div>
        </div>
      </div>
    );
  }
}
